export const sortBackendDataTitleRendered = (a, b) => {
  const itemA = a.title.rendered.toUpperCase()
  const itemB = b.title.rendered.toUpperCase()

  let comparison = 0
  if (itemA > itemB) {
    comparison = 1
  } else if (itemA < itemB) {
    comparison = -1
  }
  return comparison
}
