import styled from "styled-components"

export const JobWrapper = styled.div`
  padding-bottom: 15px;
  padding-top: 30px;
  padding-left: 30px;
  width: 90%;
  /* background-color: #363a36; */
  @media screen and (max-width: 820px) {
    margin-top: 0;
    padding-top: 2em;
    width: 100%;
  }
`

export const ContentTitle = styled.div`
  font-size: 1.8em;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 1rem;

  @media screen and (max-width: 820px) {
    font-size: 1.3em;
  }
`
export const Content = styled.div`
  font-size: 1.2em;
  margin-left: 0px;
  padding-bottom: 0.5em;
  text-decoration: none;
  text-align: none;
  text-justify: none;

  margin-bottom: ${({ smallMargin }) => (smallMargin ? "0em" : "2em")};
  max-width: 80%;
  word-wrap: break-word;
  /* background-color: red; */

  @media screen and (max-width: 820px) {
    font-size: 1em;
    width: 100%;
    padding-bottom: 0;
    margin-left: 1%;
    margin-right: 1%;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 1em;
  padding-left: 1em;
  /* background-color: red; */
  @media screen and (max-width: 820px) {
    justify-content: center;
    align-items: center;
    padding-bottom: 2em;
  }
`

export const ButtonWrapper = styled.div`
  max-width: 180px;
  font-size: 1.2em;
  margin-right: 4em;

  @media screen and (max-width: 820px) {
    font-size: 1em;
    margin-right: 1em;
  }
`
