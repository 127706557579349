import styled from "styled-components"

export const BoxWrapper = styled.div`
  padding-top: 10px;
  width: 50%;
  margin-right: 2%;
  margin-left: 2%;

  background-color: ${props => (props.dark ? "var(--main-color-black-transparent)" : "var(--main-color-beach-transparent)")};
  border-radius: 25px;

  display: flex;
  flex-direction: column;
  justify-content: flex-center;
  align-items: center;

  @media screen and (max-width: 820px) {
    margin-right: 0%;
    margin-left: 0%;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0.5em;
    border-radius: 15px;
    width: 100%;
  }
`

export const ContentTitle = styled.div`
  font-size: 2em;
  font-weight: bold;
  text-decoration: none;
  margin-top: 0.8em;
  margin-bottom: 0.8rem;
  color: ${props => (props.dark ? "var(--main-color-pink)" : "var(--main-color-pink)")};
  /* font-family: "Font-SemiBold"; */
  font-family: "Font-Normal";

  @media screen and (max-width: 820px) {
    font-size: 1.3em;
  }
`

export const ContentText = styled.div`
  font-size: 1.3em;
  margin-left: 0px;
  padding-bottom: 0.5em;
  color: ${props => (props.dark ? "var(--main-color-white)" : "var(--main-color-black)")};

  text-decoration: none;
  text-align: none;
  text-justify: none;
  margin-bottom: ${({ smallMargin }) => (smallMargin ? "0em" : "2em")};
  max-width: 90%;
  word-wrap: break-word;

  @media screen and (max-width: 820px) {
    font-size: 1em;
  }
`
export const ContentItems = styled.div`
  margin: 0 auto;
  margin-top: 1em;
  width: 90%;
  margin-bottom: 2rem;
  columns: 2;
  /* background-color: red; */
  @media screen and (max-width: 820px) {
    font-size: 1em;
    columns: 1;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  margin-bottom: 1em;
  @media screen and (max-width: 820px) {
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
  }
`

export const ButtonWrapper = styled.div`
  max-width: 250px;
  font-size: 1.2em;

  @media screen and (max-width: 820px) {
    font-size: 1em;
    margin-right: 1em;
  }
`
