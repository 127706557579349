// Libraries
import React, { useState, useEffect } from "react"

// Services
import { getBackendData } from "../../services/GetBackendData"
import { sortBackendDataTitleRendered } from "../../services/SortBackendData"

// Components
import SkillItem from "./SkillItem"

// Styled components
import { ButtonHashLink } from "../ButtonElements"
import { BoxWrapper, ContentTitle, ContentItems, ButtonsWrapper, ButtonWrapper } from "../Page/BoxElements"

// JOHME: Scroll link
// https://coderedirect.com/questions/368559/react-scroll-how-to-scroll-to-a-specific-targeted-component-when-clicking-on-n
function SkillAreas({ landingPageInfo }) {
  const [skills, setSkills] = useState(false)

  useEffect(() => {
    getBackendData(setSkills, "skills")
  }, [])

  if (skills) {
    return (
      <BoxWrapper dark>
        <ContentTitle dark>{landingPageInfo[0].acf.skills_title}</ContentTitle>
        <ContentItems>
          {skills.sort(sortBackendDataTitleRendered).map(skill => (
            <SkillItem workItem={skill} key={skill.id} />
          ))}
        </ContentItems>
        <ButtonsWrapper>
          <ButtonWrapper>
            <ButtonHashLink to="/about#skills">Läs mer</ButtonHashLink>
          </ButtonWrapper>
        </ButtonsWrapper>
      </BoxWrapper>
    )
  }
  return <div className="loader justified-loader"></div>
}

export default SkillAreas
