import styled from "styled-components"

export const JobWrapper = styled.div`
  padding-bottom: 15px;
  padding-top: 10px;
  margin-bottom: 15px;
  padding-left: 30px;
  width: 100%;
  border-radius: 25px;
  background-color: var(--main-color-beach-transparent);

  /* background-color: green; */
  @media screen and (max-width: 820px) {
    /* flex-direction: column; */
    /* padding: 10px 0; */
    /* background-color: green; */
  }
`

export const JobTitle = styled.div`
  font-size: 1.3em;
  font-weight: bold;
  font-family: Font-SemiBold;
  text-decoration: none;

  margin-top: 0.5em;
  margin-bottom: 0.5em;
  /* font-family:  */
  color: var(--main-color-pink);
  @media screen and (max-width: 820px) {
    font-size: 1.2em;
  }
`
export const JobSummeryDescription = styled.div`
  font-size: 1.1em;
  text-decoration: none;
  max-width: 90%;
  padding-bottom: 10px;
  /* background-color: lightblue; */
  justify-content: space-between;
  text-justify: none;
  @media screen and (max-width: 820px) {
    font-size: 1em;
  }
`

export const ReadMoreButtonWrapper = styled.div`
  max-width: 140px;

  @media screen and (max-width: 820px) {
    font-size: 0.8em;
  }
`
