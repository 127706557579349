import React from "react"
import { FaBars } from "react-icons/fa"
import { IconContext } from "react-icons/lib"
import { MobileIcon, Nav, NavbarContainer, NavbarBrandImage, NavLinks, NavLogo, NavMenu } from "./NavbarElements"

function Navbar({ toggle }) {
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/">
              <NavbarBrandImage />
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>

            <NavMenu>
              <NavLinks to="/" exact={true}>
                Hem
              </NavLinks>
              <NavLinks to="/about">Om oss</NavLinks>
              <NavLinks to="/career">Karriär</NavLinks>
              {/*TODO: Add when texts are done <NavLinks to="/fun">Kul</NavLinks>*/}
              <NavLinks to="/contact">Kontakt</NavLinks>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar
