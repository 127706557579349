// Libraries
import React, { useState, useEffect } from "react"

// Services
import { getBackendData } from "../../services/GetBackendData"

// Styled components
import { ButtonAnchor } from "./../ButtonElements"
import { FindUsWrap, FindUsContainer, FindUsImage, FindUsAdressItems, FindUsAdressItem, FindUsAdressTitle } from "./FindUsElements"
import { PageSectionLoading } from "./../Page/Page"

function FindUs() {
  const [officeInfo, setOfficeInfo] = useState(false)

  useEffect(() => {
    getBackendData(setOfficeInfo, "office_info")
  }, [])

  if (!officeInfo) {
    return <PageSectionLoading />
  }

  return (
    <FindUsContainer>
      <FindUsWrap>
        <FindUsAdressItems>
          <FindUsAdressTitle>Hitta oss</FindUsAdressTitle>
          <FindUsAdressItem dangerouslySetInnerHTML={{ __html: officeInfo[0].acf.name }} />
          <FindUsAdressItem dangerouslySetInnerHTML={{ __html: officeInfo[0].acf.street }} />
          <FindUsAdressItem dangerouslySetInnerHTML={{ __html: officeInfo[0].acf.postal_code_city }} />
          <FindUsAdressItem dangerouslySetInnerHTML={{ __html: officeInfo[0].acf.country }} />
          <ButtonAnchor href={officeInfo[0].acf.link_to_external_map} target="_blank" rel="noopener noreferrer">
            Karta
          </ButtonAnchor>
        </FindUsAdressItems>
      </FindUsWrap>
      <FindUsWrap>
        <FindUsImage />
      </FindUsWrap>
    </FindUsContainer>
  )
}

export default FindUs
