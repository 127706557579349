import React from "react"

import {
  ContactCardContentWrapper,
  ContactCardName,
  ContactCardTitle,
  ContactCardEmail,
  ContactCardPhone
} from "./EmployeeCardContactInfoElements"

const EmployeeContactInfo = ({ card }) => {
  return (
    <ContactCardContentWrapper>
      <ContactCardName>{card.title.rendered}</ContactCardName>

      <ContactCardTitle>{card.acf.title_description}</ContactCardTitle>

      <ContactCardEmail className="contact-card-email">
        <span className="fa-icon">
          <i className="fa fa-envelope-o" aria-hidden="true"></i>
        </span>{" "}
        <a href={"mailto:" + card.acf.email}>{card.acf.email}</a>
      </ContactCardEmail>
      <ContactCardPhone className="contact-card-phone">
        <span className="fa-icon">
          <i className="fa fa-phone" aria-hidden="true"></i>
        </span>{" "}
        <a href={"tel:" + card.acf.phone}>+{card.acf.phone}</a>
      </ContactCardPhone>
    </ContactCardContentWrapper>
  )
}

export default EmployeeContactInfo
