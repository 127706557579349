import React from "react"
import CookieConsent from "react-cookie-consent"

function SkarpCookieConsent() {
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Acceptera cookies"
        cookieName="skarpSystemCookie"
        style={{ background: "#0C1C27", padding: "0em 1.5em 0em 1.5em" }}
        buttonStyle={{
          color: "#fff",
          fontSize: "1em",
          backgroundColor: "var(--main-color-pink)",
          padding: ".5em 2em .7em 2em",
          borderRadius: "5px"
        }}
        expires={150}
      >
        Siten använder cookies för att förbättra din upplevelse <span style={{ fontSize: "10px" }}></span>
      </CookieConsent>
    </>
  )
}

export default SkarpCookieConsent
