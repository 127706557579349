import styled from "styled-components"

/* Wrapper container for all employee cards */
export const EmployeeCardsWrapperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1em;
  width: 100%;

  /* background-color: red; */

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`

/* Wrapper for a column with employee cards */
export const EmployeeCardsWrapper = styled.div`
  /* background-color: black; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1em;
  width: 43%;
  margin: 0em 3em 0em 0em;

  @media screen and (max-width: 820px) {
    width: 100%;
  }
`

export const EmployeeSuperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1em 2em 1.5em 2em;
  width: 100%;

  border-radius: 25px;

  background-color: ${props => (props.dark ? "var(--main-color-gray-brown-dark)" : "var(--main-color-beach-transparent)")};

  @media screen and (max-width: 820px) {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    padding-left: 1em;
    padding-right: 1em;
    flex-direction: column;
  }
`

export const EmployeeCardPhotoAndContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* width: 68%; */
  width: 450px;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0.2em;

  /* background-color: lightgreen; */
  @media screen and (max-width: 1600px) {
    width: 80%;
    /* background-color: red; */
  }
  @media screen and (max-width: 820px) {
    width: 100%;
  }
`

export const EmployeePhotoWrapper = styled.img`
  max-height: 12em;
  max-width: 12em;
  /* max-height: 120px;
  max-width: 120px; */
  margin-right: 1em;
  margin-top: 0.2em;
  margin-bottom: 1em;

  @media screen and (max-width: 1400px) {
    max-height: 10em;
    max-width: 10em;
  }

  @media screen and (max-width: 820px) {
    max-height: 8em;
    max-width: 8em;
  }
  /* background-color: blue; */
`

export const EmployeeTextWrapper = styled.div`
  /* background-color: green; */
  width: 100%;
  text-align: left;
  text-align: none;
  text-justify: none;
  color: #0c1c27;
  padding: 1em 0.3em 1em 0;
  margin-left: 0em;
  margin-right: 1em;

  @media screen and (max-width: 820px) {
    margin-bottom: 1em;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
  }
`

export const EmployeeName = styled.div`
  font-size: 2em;
  font-weight: bold;
  text-decoration: none;
  margin-top: 0.8em;
  margin-bottom: 0.8rem;
  color: ${props => (props.dark ? "var(--main-color-pink)" : "var(--main-color-pink)")};
  /* font-family: "Font-SemiBold"; */
  font-family: "Font-Normal";

  @media screen and (max-width: 820px) {
    font-size: 1.3em;
  }
`

export const ContentText = styled.div`
  font-size: 1.3em;
  margin-left: 0px;
  padding-bottom: 0.5em;
  color: ${props => (props.dark ? "var(--main-color-white)" : "var(--main-color-black)")};

  text-decoration: none;
  text-align: none;
  text-justify: none;
  margin-bottom: ${({ smallMargin }) => (smallMargin ? "0em" : "2em")};
  max-width: 90%;
  word-wrap: break-word;

  @media screen and (max-width: 820px) {
    font-size: 1em;
  }
`
export const ContentItems = styled.div`
  margin: 0 auto;
  margin-top: 1em;
  width: 90%;
  margin-bottom: 2rem;
  columns: 2;
  /* background-color: red; */
  @media screen and (max-width: 820px) {
    font-size: 1em;
    columns: 1;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  margin-bottom: 1em;
  @media screen and (max-width: 820px) {
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
  }
`

export const ButtonWrapper = styled.div`
  max-width: 250px;
  font-size: 1.2em;

  @media screen and (max-width: 820px) {
    font-size: 1em;
    margin-right: 1em;
  }
`
