import React from "react"
import AnimatedShowMore from "react-animated-show-more"
import { useMediaQuery } from "react-responsive"
import { getBackendURL } from "../../services/GetBackendData"
import EmployeeCardContactInfo from "./EmployeeCardContactInfo"
import {
  EmployeeSuperContainer,
  EmployeeCardPhotoAndContactWrapper,
  EmployeePhotoWrapper,
  EmployeeTextWrapper
} from "./EmployeeCardElements"

const EmployeeCard = ({ card, dark }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` })
  return (
    <React.Fragment key={card.id}>
      <EmployeeSuperContainer dark={dark}>
        <EmployeeCardPhotoAndContactWrapper>
          <EmployeePhotoWrapper src={getBackendURL(card.acf.img_url)} alt="img_url"></EmployeePhotoWrapper>
          <EmployeeCardContactInfo card={card}></EmployeeCardContactInfo>
        </EmployeeCardPhotoAndContactWrapper>

        {isMobile ? (
          <AnimatedShowMore
            height={0}
            toggle={({ isOpen }) => (isOpen ? "▲" : "▼")}
            speed={200}
            shadowColor="var(--main-color-beach-transparent)"
            toggleColor="var(--main-color-beach-transparent)"
          >
            <EmployeeTextWrapper dangerouslySetInnerHTML={{ __html: card.acf.description }}></EmployeeTextWrapper>
          </AnimatedShowMore>
        ) : (
          <EmployeeTextWrapper dangerouslySetInnerHTML={{ __html: card.acf.description }}></EmployeeTextWrapper>
        )}
      </EmployeeSuperContainer>
    </React.Fragment>
  )
}

export default EmployeeCard
