// Libraries
import React, { useState, useEffect } from "react"
import { EmployeeCardsWrapperContainer, EmployeeCardsWrapper } from "./EmployeeCardElements"
// Services
import { getBackendDataLimited } from "../../services/GetBackendData"
import { sortBackendDataTitleRendered } from "../../services/SortBackendData"

// Components
import EmployeeCard from "./EmployeeCard"
import { PageSectionLoading } from "./../Page/Page"

function EmployeeCards() {
  const [employees, setEmployees] = useState(false)

  useEffect(() => {
    getBackendDataLimited(setEmployees, "employees")
  }, [])

  const secondColumnStart = Math.floor(employees.length / 2)
  if (!employees) {
    return <PageSectionLoading />
  }

  return (
    <EmployeeCardsWrapperContainer>
      {/* First column */}
      <EmployeeCardsWrapper className="fade-in">
        {employees
          .sort(sortBackendDataTitleRendered)
          .slice(0, secondColumnStart)
          .map((employee, i) => (
            <EmployeeCard card={employee} key={employee.id} dark={!Boolean(i % 2)} />
          ))}
      </EmployeeCardsWrapper>

      {/* Second column */}
      <EmployeeCardsWrapper className="fade-in">
        {employees
          .sort(sortBackendDataTitleRendered)
          .slice(secondColumnStart)
          .map((employee, i) => (
            <EmployeeCard card={employee} key={employee.id} dark={!Boolean((i + 1) % 2)} />
          ))}
      </EmployeeCardsWrapper>
    </EmployeeCardsWrapperContainer>
  )
}

export default EmployeeCards

// return (
//   <React.Fragment>
//     <div className="employee-cards-wrapper fade-in">
//       {employees
//         .sort(sortBackendDataTitleRendered)
//         .slice(0, secondColumnStart)
//         .map(employee => (
//           <EmployeeCard card={employee} key={employee.id} />
//         ))}
//     </div>
//     <div className="employee-cards-wrapper fade-in">
//       {employees
//         .sort(sortBackendDataTitleRendered)
//         .slice(secondColumnStart)
//         .map(employee => (
//           <EmployeeCard card={employee} key={employee.id} />
//         ))}
//     </div>
//   </React.Fragment>
// )
