// Libraries
import React, { useState, useEffect } from "react"

// Services
import { getBackendData } from "../../services/GetBackendData"

// Components
import CoreValueItem from "./CoreValueItem"
import { PageSectionLoading } from "./../Page/Page"

function CoreValues() {
  const [coreValues, setCoreValues] = useState(false)

  useEffect(() => {
    getBackendData(setCoreValues, "core_values")
  }, [])

  if (!coreValues) {
    return <PageSectionLoading />
  }

  return (
    <div className="core-values-wrapper">
      {coreValues.map(value => (
        <CoreValueItem value={value} key={value.id} />
      ))}
    </div>
  )
}

export default CoreValues
