// Libraries
import React from "react"
import { Link } from "react-router-dom"

// Services
import { getBackendURL } from "../../services/GetBackendData"

function WeWorkAtNewsItem({ activity, id }) {
  return (
    <div className="news-item fade-in">
      <Link to={`/fun/activity${id}`} className="text-decoration-none">
        <img className="news-item-image" src={getBackendURL(activity.acf.image_url)} alt={activity.acf.image_url_alt} />
        <p>
          {activity.title.rendered}
          <br />
          <span>{activity.date.substring(0, activity.date.length - 9)}</span>
        </p>
      </Link>
    </div>
  )
}

export default WeWorkAtNewsItem
