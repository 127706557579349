import styled from "styled-components"
import { FaArrowCircleUp } from "react-icons/fa"

export const ScrollToTopButton = styled(FaArrowCircleUp)`
  position: fixed;
  right: 2em;
  bottom: 2em;

  width: 50px;
  height: 50px;

  color: var(--main-color-pink);
  z-index: 1000;
  justify-content: "flex-end";

  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;

  &:hover {
    transition: all 0.2s ease-in-out;
    opacity: 1;
  }

  @media screen and (max-width: 820px) {
    right: 0.5em;
  }
`
