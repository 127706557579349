import styled from "styled-components"

export const WorkPromotionContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  max-width: 1400px;
  margin: 0 auto;
  /* background-color: lightblue; */
  @media screen and (max-width: 820px) {
    flex-direction: column;
    margin-bottom: 3em;
    width: 90%;
  }
`

export const WorkItemTitle = styled.div`
  font-size: 1.6em;

  font-weight: bold;
  margin-left: 10px;
  font-family: "Font-SemiBold";
  text-decoration: none;
  margin-bottom: 0.6rem;
  color: var(--main-color-pink);

  @media screen and (max-width: 820px) {
    font-size: 1.3em;
  }
`

export const WorkItem = styled.div`
  font-size: 1.2em;
  margin-left: 10px;

  text-decoration: none;
  margin-bottom: 0.8rem;
  line-height: 1.8;
  font-weight: ${({ fontBold }) => (fontBold ? "bold" : "none")};

  @media screen and (max-width: 820px) {
    font-size: 1em;
  }
`
