import React, { useEffect } from "react"
import { PageContainer, PageTitle } from "./PageElements"

function Page(props) {
  useEffect(() => {
    /* If defined, add title to page name  */
    document.title = props.title !== undefined ? `${props.title} | Skarp` : "Skarp"
    // window.scrollTo(0, 0)
  }, [props])

  return (
    <PageContainer noPadding={props.noPadding}>
      {props.pageTitle !== undefined ? (
        <PageTitle>
          {props.pageTitle}
          {/* <HorizontalLine /> */}
        </PageTitle>
      ) : (
        <></>
      )}
      {props.children}
    </PageContainer>
  )
}

export function PageLoading() {
  return (
    <Page>
      <PageSectionLoading />
    </Page>
  )
}

export function PageSectionLoading() {
  return (
    <div className="loader-wrapper">
      <div className="loader"></div>
    </div>
  )
}

export default Page
