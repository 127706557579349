import React from "react"
import {
  SidebarContainer,
  SidebarLogo,
  SidebarBrandImage,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink
} from "./SidebarElements"

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <SidebarLogo to="/">
        <SidebarBrandImage />
      </SidebarLogo>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink onClick={toggle} to="/" exact={true}>
            Hem
          </SidebarLink>
          <SidebarLink onClick={toggle} to="/about">
            Om oss
          </SidebarLink>
          <SidebarLink onClick={toggle} to="/career">
            Karriär
          </SidebarLink>
          {/*TODO uncomment when we have something to show
          <SidebarLink onClick={toggle} to="/fun">
            Kul
          </SidebarLink>*/}
          <SidebarLink onClick={toggle} to="/contact">
            Kontakt
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
