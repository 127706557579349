// Libraries
import React from "react"

import { ButtonLink } from "../ButtonElements"
import { BoxWrapper, ContentTitle, ContentText, ButtonsWrapper, ButtonWrapper } from "../Page/BoxElements"

function WeWorkAtLanding({ landingPageInfo }) {
  return (
    <BoxWrapper dark>
      <ContentTitle dark>{landingPageInfo[0].acf.short_about_us_title}</ContentTitle>
      <ContentText dark dangerouslySetInnerHTML={{ __html: landingPageInfo[0].acf.short_about_us_text }} />

      <ButtonsWrapper>
        <ButtonWrapper>
          <ButtonLink to="/career">Jobba hos oss!</ButtonLink>
        </ButtonWrapper>
      </ButtonsWrapper>
    </BoxWrapper>
  )
}

export default WeWorkAtLanding
