// Libraries
import EmployeeCards from "./EmployeeCards"
import WeWorkWith from "./WeWorkWith"
import Page, { PageSectionLoading } from "../Page/Page"
import { PageTextSectionWrapper, PageContentWrapper } from "../Page/PageElements"
import React, { useState, useEffect } from "react"

// Services
import { getBackendData } from "../../services/GetBackendData"

function AboutPage() {
  const [aboutPageInfo, setAboutPageInfo] = useState(false)

  useEffect(() => {
    getBackendData(setAboutPageInfo, "about_us_page")
  }, [])

  if (!aboutPageInfo) {
    return <PageSectionLoading />
  }

  return (
    <Page docTitle="Om oss">
      <PageTextSectionWrapper>
        <h1>{aboutPageInfo[0].acf.employees_title}</h1>
        <p dangerouslySetInnerHTML={{ __html: aboutPageInfo[0].acf.employees_text }}></p>
      </PageTextSectionWrapper>

      <EmployeeCards />

      <div className="about-skills-and-info-wrapper">
        <div className="we-work-with-page-container">
          <div className="anchor-link-offset" id={"skills"} />
          <PageTextSectionWrapper>
            <h1>{aboutPageInfo[0].acf.skills_title}</h1>
            <p dangerouslySetInnerHTML={{ __html: aboutPageInfo[0].acf.skills_text }}></p>
          </PageTextSectionWrapper>
          <WeWorkWith />
        </div>

        <PageContentWrapper>
          <PageTextSectionWrapper largePaddingBottom>
            <h1>{aboutPageInfo[0].acf.vision_background_title}</h1>
            <p dangerouslySetInnerHTML={{ __html: aboutPageInfo[0].acf.vision_background_text }}></p>
          </PageTextSectionWrapper>
        </PageContentWrapper>
      </div>
    </Page>
  )
}

export default AboutPage
