import React from "react"
import AnimatedShowMore from "react-animated-show-more"
import { getServerIp } from "../../services/proxy"
import { useMediaQuery } from "react-responsive"

const WeWorkWithItem = ({ workItem }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` })

  return (
    <div className="work-item-container">
      <div className="work-item-wrapper">
        <img className="work-icon-image" src={`${getServerIp()}` + workItem.acf.img_url} alt="img_url" />
        <p className="work-label">{workItem.title.rendered}</p>
      </div>
      {isMobile ? (
        <AnimatedShowMore
          height={100}
          toggle={({ isOpen }) => (isOpen ? "▲" : "▼")}
          speed={200}
          shadowColor="var(--main-color-beach-transparent)"
          toggleColor="var(--main-color-beach-transparent)"
        >
          <p className="work-area-text" dangerouslySetInnerHTML={{ __html: workItem.acf.text }}></p>
        </AnimatedShowMore>
      ) : (
        <p className="work-area-text" dangerouslySetInnerHTML={{ __html: workItem.acf.text }}></p>
      )}
    </div>
  )
}

export default WeWorkWithItem
