// Libraries
import React, { useState, useEffect, useRef } from "react"

// Services
import { getBackendData } from "../../services/GetBackendData"

// Components
import Page from "../Page/Page"
import LandingImage from "./LandingImage"
import PageSectionWork from "./PageSectionWork"
import PageSectionValues from "./PageSectionValues"

function LandingPage() {
  const scrollToSectionRef = useRef(null)
  const [landingPageInfo, setLandingPageInfo] = useState(false)

  // Get landing page data from backend
  useEffect(() => {
    getBackendData(setLandingPageInfo, "landing_page")
  }, [])

  const scrollToElement = () => {
    window.scrollTo({
      behavior: "smooth",
      top: scrollToSectionRef.current.offsetTop - 100
    })
  }

  // If loading, return landing image loading
  if (!landingPageInfo) {
    return (
      <Page noPadding={true}>
        <LandingImage loading={true} />
      </Page>
    )
  }

  return (
    <Page docTitle="Hem" noPadding={true}>
      <LandingImage scrollToElement={scrollToElement} landingImageText={landingPageInfo[0].acf.landing_image_text} />
      <div className="landing-main">
        <div ref={scrollToSectionRef} className="page-sect-landing-first-words-wrapper">
          <div className="landing-first-words-wrapper">
            <h1>{landingPageInfo[0].acf.first_words_title}</h1>
            <p className="landing-first-words-wrapper-p" dangerouslySetInnerHTML={{ __html: landingPageInfo[0].acf.first_words }} />
          </div>
        </div>
        {/* "Vad vi gör" ; "Vi jobbar med" */}
        <PageSectionWork landingPageInfo={landingPageInfo} />

        {/* "Kärnvärden" */}
        <PageSectionValues landingPageInfo={landingPageInfo} />
      </div>
    </Page>
  )
}

export default LandingPage
