// Libraries
import React, { useState, useEffect } from "react"

// Components
import ContactCard from "./ContactCard"
import { PageSectionLoading } from "./../Page/Page"
// Services
import { sortBackendDataTitleRendered } from "../../services/SortBackendData"
import { getBackendDataLimited } from "../../services/GetBackendData"

function ContactCards() {
  const [contactCards, setContactCards] = useState(false)

  useEffect(() => {
    getBackendDataLimited(setContactCards, "employees")
  }, [])

  if (!contactCards) {
    return <PageSectionLoading />
  }

  return (
    <React.Fragment>
      <div className="contact-cards-wrapper fade-in">
        {contactCards
          .sort(sortBackendDataTitleRendered)
          .reverse()
          .map(contact => (
            <ContactCard card={contact} key={contact.id} />
          ))}
      </div>
    </React.Fragment>
  )
}

export default ContactCards
