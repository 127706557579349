import axios from "axios"
import { getServerIp } from "./proxy"

export const getBackendData = async function (setData, source) {
  try {
    const response = await axios.get(`${getServerIp()}/wp-json/wp/v2/` + source)
    setData(response.data)
  } catch (err) {
    console.log(err)
  }
}

export const getBackendDataLimited = async function (setData, source) {
  try {
    const response = await axios.get(`${getServerIp()}/wp-json/wp/v2/` + source + "/?per_page=100")
    setData(response.data)
  } catch (err) {
    console.log(err)
  }
}

export function getBackendURL(itemUrl) {
  return `${getServerIp()}` + itemUrl
}
