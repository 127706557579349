import React, { useEffect, useState } from "react"

// Services
import { getBackendData } from "../../../services/GetBackendData"

// Styled Components
import { JobWrapper, ContentTitle, Content, ButtonsWrapper, ButtonWrapper } from "./JobItemPageElements"
import { ButtonLink } from "../../ButtonElements"
import Page, { PageLoading } from "../../Page/Page"
import { SkarpSBackgoundWrapper } from "../../Page/PageElements"

function JobItemPage(props) {
  const { id } = props.match.params
  const [job, setJob] = useState(false)
  const [jobItemPageInfo, setJobItemPageInfo] = useState(false)

  useEffect(() => {
    getBackendData(setJob, `career_hiring/${id}`)
  }, [id])

  useEffect(() => {
    getBackendData(setJobItemPageInfo, "job_item_page")
  }, [])

  if (!job || !jobItemPageInfo) {
    return <PageLoading />
  }

  return (
    <Page>
      <SkarpSBackgoundWrapper>
        <JobWrapper>
          <ContentTitle>
            {jobItemPageInfo[0].acf.pre_text_job_title} - {job.title.rendered}
          </ContentTitle>

          <Content dangerouslySetInnerHTML={{ __html: jobItemPageInfo[0].acf.first_section }} />

          <ContentTitle>{jobItemPageInfo[0].acf.about_job_title}</ContentTitle>
          <Content dangerouslySetInnerHTML={{ __html: job.acf.job_description }} />

          <ContentTitle>{jobItemPageInfo[0].acf.about_you_title}</ContentTitle>
          <Content dangerouslySetInnerHTML={{ __html: job.acf.about_you }} />

          <ContentTitle>{jobItemPageInfo[0].acf.what_we_offer_title}</ContentTitle>
          <Content smallMargin={true} dangerouslySetInnerHTML={{ __html: jobItemPageInfo[0].acf.what_we_offer }} />
          {/* what_we_offer */}
        </JobWrapper>

        <ButtonsWrapper>
          <ButtonWrapper>
            <ButtonLink to="/career">
              <i className="fa fa-caret-left" aria-hidden="true">
                {" "}
                Tillbaka
              </i>
            </ButtonLink>
          </ButtonWrapper>
          <ButtonWrapper>
            <ButtonLink to="/contact">
              <i className="fa fa-caret-right" aria-hidden="true">
                {" "}
                Kontakta oss
              </i>
            </ButtonLink>
          </ButtonWrapper>
        </ButtonsWrapper>
      </SkarpSBackgoundWrapper>
    </Page>
  )
}
export default JobItemPage
