// Libraries
import React, { useState, useEffect } from "react"

// Components
import Page, { PageLoading } from "../Page/Page"
import { PageContentWrapper } from "../Page/PageElements"
import AvailableJobs from "./AvailableJobs/AvailableJobs"
import { useMediaQuery } from "react-responsive"

// Styled components
import { ButtonLink } from "./../ButtonElements"

import {
  TestimoniesWrapper,
  TestimonyWrapper,
  TestimoniesImageWrapper,
  TestimoniesTextWrapper,
  TestimoniesImage,
  WorkplaceContentWrapper,
  WorkplaceContentTitle,
  ContactButtonWrapper
} from "./CareerPageElements"

// Services
import { getBackendData, getBackendURL } from "../../services/GetBackendData"

function CareerPage() {
  const [careerPageInfo, setCareerPageInfo] = useState(false)
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` })

  useEffect(() => {
    getBackendData(setCareerPageInfo, "career_page")
  }, [])

  if (!careerPageInfo) {
    return <PageLoading />
  }
  // pageTitle={careerPageInfo[0].acf.carrer_page_title}
  return (
    <Page docTitle="Karriär">
      <PageContentWrapper>
        <TestimoniesWrapper>
          {careerPageInfo[0].acf.show_testimony_1 ? (
            <TestimonyWrapper>
              <TestimoniesImageWrapper>
                <TestimoniesImage
                  src={getBackendURL(careerPageInfo[0].acf.testimony_image_url_1)}
                  alt="testimony_image_url_1"
                ></TestimoniesImage>
              </TestimoniesImageWrapper>
              <TestimoniesTextWrapper left>
                <p dangerouslySetInnerHTML={{ __html: careerPageInfo[0].acf.testimony_text_1 }}></p>
              </TestimoniesTextWrapper>
            </TestimonyWrapper>
          ) : (
            <></>
          )}

          {careerPageInfo[0].acf.show_testimony_2 ? (
            <>
              {isMobile ? (
                <TestimonyWrapper>
                  <TestimoniesImageWrapper>
                    <TestimoniesImage
                      src={getBackendURL(careerPageInfo[0].acf.testimony_image_url_2)}
                      alt="testimony_image_url_2"
                    ></TestimoniesImage>
                  </TestimoniesImageWrapper>
                  <TestimoniesTextWrapper>
                    <p dangerouslySetInnerHTML={{ __html: careerPageInfo[0].acf.testimony_text_2 }}></p>
                  </TestimoniesTextWrapper>
                </TestimonyWrapper>
              ) : (
                <TestimonyWrapper>
                  <TestimoniesTextWrapper>
                    <p dangerouslySetInnerHTML={{ __html: careerPageInfo[0].acf.testimony_text_2 }}></p>
                  </TestimoniesTextWrapper>
                  <TestimoniesImageWrapper>
                    <TestimoniesImage
                      src={getBackendURL(careerPageInfo[0].acf.testimony_image_url_2)}
                      alt="testimony_image_url_2"
                    ></TestimoniesImage>
                  </TestimoniesImageWrapper>
                </TestimonyWrapper>
              )}
            </>
          ) : (
            <></>
          )}
        </TestimoniesWrapper>

        <WorkplaceContentWrapper largeFont largeMarginBottom>
          <WorkplaceContentTitle
            pink
            noMarginBottom
            dangerouslySetInnerHTML={{ __html: careerPageInfo[0].acf.workplace_description_title }}
          />
          <p dangerouslySetInnerHTML={{ __html: careerPageInfo[0].acf.workplace_description }}></p>
        </WorkplaceContentWrapper>
        {/*TODO: uncomment when we have real job offerings*/}
        {/*<AvailableJobs careerPageInfo={careerPageInfo} />*/}

        <WorkplaceContentWrapper largeFont largeLineHeight largeMarginTop largeMarginBottom>
          <WorkplaceContentTitle pink noMarginBottom dangerouslySetInnerHTML={{ __html: careerPageInfo[0].acf.work_with_us_title }} />
          <p dangerouslySetInnerHTML={{ __html: careerPageInfo[0].acf.work_with_us_text }} />
          <ContactButtonWrapper>
            <ButtonLink to="/contact">Kontakta oss</ButtonLink>
          </ContactButtonWrapper>
        </WorkplaceContentWrapper>
      </PageContentWrapper>
    </Page>
  )
}

export default CareerPage
