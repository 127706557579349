import styled from "styled-components"

export const TestimoniesWrapper = styled.div`
  width: 100%;
  border-radius: 25px;
  background-color: ${props => (props.dark ? "lightgray" : "var(--main-color-beach-transparent)")};
  margin-bottom: 1em;
  margin-top: 10px;
  padding: 15px 15px 15px 15px;

  @media screen and (max-width: 820px) {
    padding: 0;
  }
`
export const TestimonyWrapper = styled.div`
  display: flex;
  margin-bottom: 1em;

  @media screen and (max-width: 820px) {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    padding-left: 1em;
    padding-right: 1em;
    flex-direction: column;
    /* background-color: red; */
  }
`
export const TestimoniesImageWrapper = styled.div`
  /* float: left; */
  float: ${props => (props.left ? "left" : "right")};
  /* background-color: blue; */
  width: 40%;
  padding: 5px;
  @media screen and (max-width: 820px) {
    width: 100%;
    float: none;
  }
`

export const TestimoniesTextWrapper = styled.div`
  float: ${props => (props.left ? "left" : "right")};
  /* background-color: brown; */
  width: 60%;
  padding: 5px;
  font-size: 1em;

  @media screen and (max-width: 820px) {
    width: 100%;
  }

  p {
    text-justify: none;
    text-align: none;
    float: ${props => (props.left ? "left" : "right")};
    margin-left: ${props => (props.left ? "1.5em" : "3em")};
    margin-right: ${props => (props.left ? "3em" : "1.5em")};
    width: 95%;
    /* background-color: blue; */
    @media screen and (max-width: 820px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
`

export const TestimoniesImage = styled.img`
  /* background-size: cover; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  height: auto;
  max-width: 100%;
  max-height: 100%;
`

export const WorkplaceContentWrapper = styled.div`
  justify-content: space-between;
  text-justify: none;
  width: 100%;
  /* background-color: pink; */
  margin-top: ${props => (props.largeMarginTop ? "2em" : "0em")};
  margin-bottom: ${props => (props.largeMarginBottom ? "2em" : "0em")};

  /* margin: 10px 20px; */

  /* background-color: lightblue; */
  @media screen and (max-width: 820px) {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    padding-left: 1em;
    padding-right: 1em;
    flex-direction: column;
  }

  p {
    /* background-color: red; */
    text-justify: none;
    text-align: none;
    float: left;

    margin-left: ${props => (props.left ? "1.5em" : "3em")};
    margin-right: ${props => (props.left ? "3em" : "1.5em")};
    font-size: ${props => (props.largeFont ? "1.2em" : "1em")};
    line-height: ${props => (props.largeLineHeight ? "1.5em" : "none")};
    width: 75%;
    /* background-color: blue; */
    @media screen and (max-width: 820px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      font-size: 1.1em;
    }
  }
`

export const WorkplaceContentTitle = styled.div`
  font-size: 1.4em;

  margin-left: 10px;
  font-family: "Font-SemiBold";
  font-weight: bold;
  text-decoration: none;
  color: ${props => (props.pink ? "var(--main-color-pink)" : "black")};
  margin-bottom: ${props => (props.noMarginBottom ? "0" : "0.6em")};

  @media screen and (max-width: 820px) {
    font-size: 1.3em;
    /* background-color: yellow; */
    text-align: center;
    text-justify: auto;
  }
`

export const ContactButtonWrapper = styled.div`
  width: 30%;
  /* background-color: blue; */
  @media screen and (max-width: 820px) {
    font-size: 1em;
    font-weight: bold;
    width: 100%;
  }
`
