// Libraries
import React, { useEffect, useState } from "react"

// Services
import { getBackendDataLimited } from "../../services/GetBackendData"

// Components
import WeWorkAtNewsItem from "./WeWorkAtNewsItem"
import { PageSectionLoading } from "./../Page/Page"

// Styled components
import { Button } from "../ButtonElements"

function WeWorkAtNews() {
  const [blogItems, setBlogItems] = useState(false)
  const [visibleItems, setVisibleItems] = useState(4)

  useEffect(() => {
    getBackendDataLimited(setBlogItems, "skarp_blog")
  }, [])

  const loadMore = () => {
    setVisibleItems(visibleItems + 2)
  }

  if (!blogItems) {
    return <PageSectionLoading />
  }

  return (
    <div className="news-container">
      <div className="news-title-container-top"></div>
      <div className="news-item-container">
        {blogItems.slice(0, visibleItems).map(activity => (
          <WeWorkAtNewsItem activity={activity} key={activity.id} id={activity.id} />
        ))}
        {visibleItems < blogItems.length && (
          <div className="button-wrapper-2">
            <Button onClick={loadMore} className="read-more-button">
              Ladda fler
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default WeWorkAtNews
