import React from "react"

import WeWorkAtLanding from "./WeWorkAtLanding"
import SkillAreas from "./SkillAreas"

function PageSectionWork({ landingPageInfo }) {
  return (
    <div className="page-sect-work-wrapper">
      <WeWorkAtLanding landingPageInfo={landingPageInfo} />
      <SkillAreas landingPageInfo={landingPageInfo} />
    </div>
  )
}

export default PageSectionWork
