import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import Scroll from "react-scroll"
import { HashLink } from "react-router-hash-link"

const baseInputStyles = css`
  border-radius: 50px;
  /* background: ${({ primary }) => (primary ? "var(--main-color-gray)" : "#010606")}; */
  background: linear-gradient(90deg, #fa3775, #b51864);
  white-space: nowrap;
  padding: ${({ big }) => (big ? "10px 50px" : "9px 30px")};
  margin: ${({ largeMargin }) => (largeMargin ? "30px 30px" : "10px 10px")};
  color: white;
  border: 3px solid palevioletred;
  /* background: white; */

  font-size: 1.2em;

  border-radius: 5px;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    border: 3px solid var(--main-color-gray);

    font-style: bold;
    color: ${({ primary }) => (primary ? "var(--main-color-pink-light)" : "white")};
  }
`

export const Button = styled.button`
  ${baseInputStyles}
`

export const ButtonLink = styled(Link)`
  ${baseInputStyles}
`

export const ButtonHashLink = styled(HashLink)`
  ${baseInputStyles}
`

export const ButtonScrollLink = styled(Scroll.ScrollLink)`
  ${baseInputStyles};
`

export const ButtonAnchor = styled.a`
  ${baseInputStyles}
`
