// Libraries
import React from "react"

function LandingImage({ scrollToElement, landingImageText, loading }) {
  if (loading) {
    return (
      <div className="landing-image-wrapper">
        <div className="landning-image-content-wrapper">
          <img alt="brand_logo_square_img_url" className="pulse pulsing-logo"></img>
        </div>
      </div>
    )
  }

  return (
    <div className="landing-image-wrapper">
      <div className="landning-image-content-wrapper fade-in">
        <img className="landing-image-logo" alt="brand_logo_square_img_url"></img>
        <p>{landingImageText}</p>
      </div>
      <div style={{ height: "1em", width: "100%" }}></div>
      <span className="scroll-down-arrow" onClick={scrollToElement}>
        ▼
      </span>
    </div>
  )
}

export default LandingImage
