// Libraries
import React, { useEffect, useState } from "react"

// Services
import { getBackendDataLimited, getBackendURL } from "../../services/GetBackendData"

import Page, { PageLoading } from "../Page/Page"
import { ButtonLink } from "../ButtonElements"

function FunItemPage(props) {
  const [activity, setActivity] = useState(false)
  const { id } = props.match.params
  useEffect(() => {
    getBackendDataLimited(setActivity, `skarp_blog/${id}`)
  }, [id])

  if (!activity) {
    return <PageLoading />
  }

  return (
    <Page docTitle={activity.title.rendered}>
      <div className="news-post-container">
        <div className="news-post-wrapper fade-in">
          <ButtonLink to="/fun">
            <i className="fa fa-caret-left" aria-hidden="true">
              {" "}
              Tillbaka
            </i>
          </ButtonLink>

          <h2 className="news-title">{activity.title.rendered}</h2>

          <p className="date-string">{activity.date.substring(0, activity.date.length - 9)}</p>
          <div className="author-info-wrapper">
            <img className="author-image" src={getBackendURL(activity.acf.author_image_url)} alt={activity.acf.author_image_url_alt} />
            <p className="author-name">Av {activity.acf.author}</p>
          </div>
          <img className="post-page-image" src={getBackendURL(activity.acf.image_url)} alt={activity.acf.image_url_alt} />

          <div
            className="post-info-paragraph"
            dangerouslySetInnerHTML={{
              __html: activity.content.rendered
            }}
          />
        </div>
      </div>
    </Page>
  )
}

export default FunItemPage
