import React, { useState, useEffect } from "react"
import WeWorkAtNews from "./WeWorkAtNews"
// Services
import { getBackendData } from "../../services/GetBackendData"
import Page, { PageSectionLoading } from "./../Page/Page"
import { PageTextSectionWrapper } from "../Page/PageElements"

function FunPage() {
  const [funPageInfo, setFunPageInfo] = useState(false)

  useEffect(() => {
    getBackendData(setFunPageInfo, "fun_page")
  }, [])

  if (!funPageInfo) {
    return <PageSectionLoading />
  }

  return (
    <Page docTitle="Kul">
      <PageTextSectionWrapper>
        <h1>{funPageInfo[0].acf.page_title}</h1>
        <p dangerouslySetInnerHTML={{ __html: funPageInfo[0].acf.page_text }}></p>
      </PageTextSectionWrapper>

      <WeWorkAtNews />
    </Page>
  )
}

export default FunPage
