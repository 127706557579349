import styled from "styled-components"
import { Link } from "react-router-dom"

import brandLogoWide from "./../../resources/BrandImages/skarp-systems-logo-wide-transparent.png"

export const FooterContainer = styled.footer`
  background-color: var(--main-color-black);
`

export const FooterWrap = styled.div`
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
  /* background-color: blue; */
  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`

export const FooterWrapInner = styled.div`
  padding: 10x 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
  /* background-color: lightblue; */
  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  /* background-color: purple; */
  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`

export const FooterAdressContainer = styled.div`
  display: flex;
  justify-content: center;
  /* background-color: green; */
  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`

export const FooterAdressWrapper = styled.div`
  display: flex;
  /* background-color: pink; */
  flex-direction: column;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`
export const FooterLogo = styled(Link)`
  color: var(--main-color-pink-dark);
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-left: 0px;
  font-weight: bold;
  text-decoration: none;
  @media screen and (max-width: 768px) {
    padding-bottom: 1em;
  }
`

export const FooterBrandImage = styled.img.attrs({
  src: `${brandLogoWide}`
})`
  max-height: 3em;
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
  @media screen and (max-width: 768px) {
    max-height: 3em;
  }
`

export const FooterAdressItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  text-align: left;
  width: 300px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`

export const FooterAdressItem = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.2rem;
`

export const FooterLinksWrapper = styled.div`
  display: flex;
  /* background-color: green; */

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;

  /* text-align: left; */
  align-items: center;
  max-width: 120px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`

export const FooterLinkTitle = styled(Link)`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
  margin-right: 30px;
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.2rem;

  &:hover {
    color: var(--main-color-pink-light);
    transition: 0.3s ease-out;
  }
`

export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: var(--main-color-pink-light);
    transition: 0.3s ease-out;
  }
`

export const AdressWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`

// export const SocialLogo = styled(Link)`
//   color: #fff;
//   justify-self: start;
//   cursor: pointer;
//   text-decoration: none;
//   font-size: 1.5rem;
//   display: flex;
//   align-items: center;
//   margin-bottom: 16px;
//   font-weight: bold;
// `

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
  margin-top: 16px;
`

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  width: 240px;

  @media screen and (max-width: 820px) {
    width: 10%;
  }
`

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`
