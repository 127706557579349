import React from "react"
import { Link } from "react-router-dom"
import { getBackendURL } from "../../services/GetBackendData"
import { HashLink } from "react-router-hash-link"

function SkillItem({ workItem }) {
  if (workItem.acf.display_on_landing) {
    return (
      <div className="work-item-wrapper">
        <HashLink to="/about#skills">
          <img className="work-icon-image" key={workItem.id} src={getBackendURL(workItem.acf.img_url)} alt="{workItem.acf.img_url}" />
        </HashLink>
        <p className="landing-work-label">{workItem.title.rendered}</p>
      </div>
    )
  }
  return null
}

export default SkillItem
