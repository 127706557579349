import React from "react"
import CoreValues from "./CoreValues"

function PageSectionValues({ landingPageInfo }) {
  return (
    <div className="page-sect-val-wrapper">
      <h2>{landingPageInfo[0].acf.page_section_values_title}</h2>
      <CoreValues />
    </div>
  )
}

export default PageSectionValues
