// Libraries
import React from "react"

// Services
import { getBackendURL } from "../../services/GetBackendData"

function ContactCard({ card }) {
  if (card.acf.office_contact !== false) {
    return (
      <React.Fragment key={card.id}>
        <div className="contact-card-wrapper">
          <img className="contact-icon-image" src={getBackendURL(card.acf.img_url)} alt="icon_url" />
          <div className="contact-card-text-content-wrapper">
            <p className="contact-card-name">{card.title.rendered}</p>
            <p className="contact-card-title">{card.acf.contact_description}</p>
            <p className="contact-card-email">
              <span className="fa-icon">
                <i className="fa fa-envelope-o" aria-hidden="true"></i>
              </span>{" "}
              <a href={"mailto:" + card.acf.email}>{card.acf.email}</a>
            </p>
            <p className="contact-card-phone">
              <span className="fa-icon">
                <i className="fa fa-phone" aria-hidden="true"></i>
              </span>{" "}
              <a href={"tel:" + card.acf.phone}>+{card.acf.phone}</a>
            </p>
          </div>
        </div>
      </React.Fragment>
    )
  }
  return <React.Fragment />
}

export default ContactCard
