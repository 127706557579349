// Libraries
import React, { useState, useEffect } from "react"

// Services
import { getBackendDataLimited } from "../../services/GetBackendData"
import { sortBackendDataTitleRendered } from "../../services/SortBackendData"

// Components
import WeWorkWithItem from "./WeWorkWithItem"
import { PageSectionLoading } from "./../Page/Page"

function WeWorkWith() {
  const [skills, setSkillAreas] = useState(false)

  useEffect(() => {
    getBackendDataLimited(setSkillAreas, "skills")
  }, [])

  const secondColumnStart = Math.floor(skills.length / 2)

  if (!skills) {
    return <PageSectionLoading />
  }

  return (
    <div className="work-areas-wrapper fade-in">
      <div className="work-item-container-wrapper">
        {skills
          .sort(sortBackendDataTitleRendered)
          .slice(0, secondColumnStart)
          .map(skill => (
            <WeWorkWithItem workItem={skill} key={skill.id} />
          ))}
      </div>
      <div className="work-item-container-wrapper">
        {skills
          .sort(sortBackendDataTitleRendered)
          .slice(secondColumnStart)
          .map(skill => (
            <WeWorkWithItem workItem={skill} key={skill.id} />
          ))}
      </div>
    </div>
  )
}

export default WeWorkWith
