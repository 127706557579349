import styled from "styled-components"

export const ContactCardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 25px;
  /* background-color: lightgray; */
`

export const ContactCardName = styled.div`
  font-size: 1.4em;
  font-weight: 600;
`

export const ContactCardTitle = styled.div`
  color: var(--main-color-pink);
  font-weight: 600;
`

export const ContactCardEmail = styled.div`
  font-size: 0.8em;
  @media screen and (max-width: 1600px) {
    font-size: 0.75em;
  }
`
export const ContactCardPhone = styled.div`
  font-size: 0.8em;
  @media screen and (max-width: 1600px) {
    font-size: 0.75em;
  }
`
