import styled from "styled-components"
import swedenLinkopingMap from "./../../resources/map-sweden-linkoping.png"

export const ContactContainer = styled.div`
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  background-color: blue;
  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`
export const FindUsContainer = styled.div`
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  border-radius: 25px;
  /* background-color: var(--main-color-gray-brown-dark); */
  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`
export const FindUsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50%;
  margin: 10px;
  @media screen and (max-width: 820px) {
    padding-top: 32px;
    width: 90%;
  }
`

export const FindUsImage = styled.img.attrs({
  src: `${swedenLinkopingMap}`,
  alt: "swedenLinkopingMap"
})`
  width: 60%;
  float: left;
  /* background: gray; */
  margin-right: 10px;
`

export const FindUsAdressItems = styled.div`
  /* background-color: var(--main-color-gray-brown-dark); */
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;

  text-align: left;
  width: 400px;
  box-sizing: border-box;
  color: black;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`

export const FindUsAdressTitle = styled.div`
  font-size: 1.4em;
  /* font-size: 14px; */
  font-weight: bold;
  margin-left: 10px;

  text-decoration: none;
  margin-bottom: 0.6rem;
`

export const FindUsAdressItem = styled.div`
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "14px")};
  font-size: 1.1em;

  margin-left: 10px;

  text-decoration: none;
  margin-bottom: 0.2rem;
`
