// Libraries
import React, { useState, useEffect } from "react"
import { FaLinkedin } from "react-icons/fa"

// Services
import { getBackendData } from "../../services/GetBackendData"

// Components
import { PageSectionLoading } from "./../Page/Page"

// Styled components
import {
  FooterContainer,
  FooterWrap,
  FooterWrapInner,
  FooterAdressContainer,
  FooterLinksContainer,
  FooterAdressWrapper,
  FooterAdressItems,
  FooterAdressItem,
  FooterLogo,
  FooterBrandImage,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  WebsiteRights,
  SocialIcons,
  SocialIconLink
} from "./FooterElements"

const Footer = () => {
  const [officeInfo, setOfficeInfo] = useState(false)

  useEffect(() => {
    getBackendData(setOfficeInfo, "office_info")
  }, [])

  if (!officeInfo) {
    return <PageSectionLoading />
  }

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterWrapInner>
          <FooterAdressContainer>
            <FooterAdressWrapper>
              <FooterAdressItems>
                <FooterLogo to="/">
                  <FooterBrandImage />
                </FooterLogo>
                {officeInfo.map(officeInfo => (
                  <React.Fragment key={officeInfo.id}>
                    <FooterAdressItem dangerouslySetInnerHTML={{ __html: officeInfo.acf.name }} />
                    <FooterAdressItem dangerouslySetInnerHTML={{ __html: officeInfo.acf.street }} />
                    <FooterAdressItem dangerouslySetInnerHTML={{ __html: officeInfo.acf.postal_code_city }} />
                    <FooterAdressItem dangerouslySetInnerHTML={{ __html: officeInfo.acf.country }} />
                  </React.Fragment>
                ))}
              </FooterAdressItems>
              <SocialIcons>
                <SocialIconLink href={officeInfo[0].acf.linkedin_url} target="_blank" aria-label="Linkedin">
                  <FaLinkedin />
                </SocialIconLink>
              </SocialIcons>
            </FooterAdressWrapper>
          </FooterAdressContainer>
          <FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle to="/">Hem</FooterLinkTitle>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle to="/about">Om oss</FooterLinkTitle>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle to="/career">Karriär</FooterLinkTitle>
              </FooterLinkItems>
              {/*TODO: Uncomment when we have something to show on fun page
              <FooterLinkItems>
                <FooterLinkTitle to="/fun">Kul</FooterLinkTitle>
              </FooterLinkItems>
              */}
              <FooterLinkItems>
                <FooterLinkTitle to="/contact">Kontakt</FooterLinkTitle>
              </FooterLinkItems>
            </FooterLinksWrapper>
          </FooterLinksContainer>
        </FooterWrapInner>
        <WebsiteRights>Skarp Systems AB © 2021 All rights reserved.</WebsiteRights>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
