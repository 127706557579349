// Libraries
import React, { useState, useEffect } from "react"

// Services
import { getBackendData } from "../../services/GetBackendData"

// Components
import ContactCards from "./ContactCards"
import FindUs from "./FindUs"
import Page, { PageLoading } from "../Page/Page"
import { PageContentWrapper } from "../Page/PageElements"
import { PageTextSectionWrapper, SkarpSBackgoundWrapper } from "../Page/PageElements"

// Styled Components
import { WorkPromotionContainer, WorkItem, WorkItemTitle } from "./WorkPromotionElements"

function ContactPage() {
  const [contactPageInfo, setContactPageInfo] = useState(false)

  useEffect(() => {
    getBackendData(setContactPageInfo, "contact_page")
  }, [])

  if (!contactPageInfo) {
    return <PageLoading />
  }

  return (
    <Page docTitle="Kontakt">
      <SkarpSBackgoundWrapper positionX="-20em" positionY="-10em">
        {/* <div className="s-symbol-background2"> */}
        <PageContentWrapper>
          <PageTextSectionWrapper>
            <h1>{contactPageInfo[0].acf.page_title}</h1>
            <p dangerouslySetInnerHTML={{ __html: contactPageInfo[0].acf.contact_us_text }}></p>
          </PageTextSectionWrapper>

          <ContactCards />

          <div className="work-with-us-contact-page-wrapper">
            <FindUs />
            <WorkPromotionContainer>
              <WorkItemTitle>{contactPageInfo[0].acf.work_with_us_title}</WorkItemTitle>
              <WorkItem dangerouslySetInnerHTML={{ __html: contactPageInfo[0].acf.work_with_us_text }} />
            </WorkPromotionContainer>
          </div>
        </PageContentWrapper>
      </SkarpSBackgoundWrapper>
      {/* </div> */}
    </Page>
  )
}

export default ContactPage
