import styled from "styled-components"
import { FaTimes } from "react-icons/fa"
import { NavLink } from "react-router-dom"
import brandLogoWide from "./../../../resources/BrandImages/skarp-systems-logo-wide-transparent.png"

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: var(--main-color-black);
  display: grid;
  align-items: center;
  left: 0;
  transition: 0.8s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-999%")};
`

export const SidebarLogo = styled(NavLink)`
  color: var(--main-color-pink-dark);
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-top: -50px;
  font-weight: bold;
  text-decoration: none;
`

export const SidebarBrandImage = styled.img.attrs({
  src: `${brandLogoWide}`
})`
  max-height: 3em;
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;

  @media screen and (max-width: 480px) {
    max-height: 2.4em;
  }
`

export const Nav = styled.nav`
  background: var(--main-color-gray);
`

export const CloseIcon = styled(FaTimes)`
  color: #fff;
`

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`

export const SidebarWrapper = styled.div`
  color: white;
`

export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;
  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`

export const SidebarLink = styled(NavLink)`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;

  &.active {
    border-left: 3px solid var(--main-color-pink);
    font-weight: bold;
    color: var(--main-color-pink);
  }

  &:hover {
    color: var(--main-color-pink);
    border-left: 3px solid var(--main-color-pink);
    transition: 0.2s ease-in-out;
  }
`
export const SidebarBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`

export const SidebarRoute = styled(NavLink)`
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 16px 64px;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    color: #010606;
    background: white;
    transition: 0.2s ease-in-out;
  }
`
