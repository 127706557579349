import styled from "styled-components"
import backgroundSImage from "../../resources/symbol_beige_3200.png"

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--page-background-color);
  /* padding-top: {nopadding}? "0": "2em"; */
  padding: ${props => (props.noPadding ? "0" : "0")};

  /* background-color: brown; */

  h1 {
    text-align: center;
    margin-bottom: 0.1em;

    @media screen and (max-width: 820px) {
      margin-top: 1.5em;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 0.1em;

    @media screen and (max-width: 820px) {
      margin-top: 1.5em;
    }
  }

  hr {
    border: 0;
    height: 3px;
    background-image: linear-gradient(90deg, #fa3775, #b51864, #fa3775);
    width: ${({ wideHr }) => (wideHr ? "100%" : "80%")};
  }

  @media screen and (max-width: 820px) {
    padding: 0;

    hr {
      width: 90%;
    }
  }
`

export const SkarpSBackgoundWrapper = styled.div`
  background-repeat: no-repeat;
  background-image: url(${backgroundSImage});
  background-position-x: ${props => (props.positionX ? props.positionX : "0em")};
  background-position-y: ${props => (props.positionY ? props.positionY : "0em")};
`

export const PageContentWrapper = styled.div`
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin: auto;
  width: 90%;
  /* background-color: black; */

  @media screen and (max-width: 820px) {
    flex-direction: column;
    padding: 10px 0;
    width: 100%;
  }
`

export const PageTitle = styled.div`
  font-size: 2.5em;
  color: var(--main-color-pink);
  font-family: Font-SemiBold;
  margin: auto;
  font-weight: bold;
  text-align: center;
`

export const PageTextSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 1em;
  padding-bottom: ${props => (props.largePaddingBottom ? "2.5em" : "1em")};
  /* border: 5px solid var(--main-color-black); */
  border-radius: 25px;
  height: auto;
  /* background-color: pink; */
  font-family: Font-SemiBold;

  h1 {
    font-size: 2em;
    color: var(--main-color-pink);
  }

  p {
    max-width: 70%;
    font-size: 1.2em;
    font-family: ${props => (props.Bold ? "Font-SemiBold" : "Font-Normal")};

    text-align: none;
    text-justify: none;

    margin: 0.4em;
    border-radius: 25px;
  }
`

export const PageTextSectionTitle = styled.h1`
  font-size: 2em;
  color: var(--main-color-pink);
`
export const PageTextSectionText = styled.div`
  max-width: 80%;

  font-size: 1.2em;
  /* background-color: #dd296e; */
  text-align: none;
  text-justify: none;

  margin: 0.4em;
  border-radius: 25px;

  font-family: Font-SemiBold;
`

export const HorizontalLine = styled.div`
  border: 0;
  margin: auto;
  margin-top: 0.1em;
  /* height: 3px; */
  background-image: linear-gradient(90deg, #fa3775, #b51864, #fa3775);
  /* width: 80%; */
  width: ${({ wide }) => (wide ? "100%" : "80%")};
  height: ${({ thick }) => (thick ? "5px" : "3px")};
`
