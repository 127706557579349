// Libraries
import React from "react"
import { BoxWrapper, ContentTitle, ContentText } from "../Page/BoxElements"

function CoreValueItem({ value }) {
  if (value.acf.hide) {
    return <></>
  }

  return (
    <BoxWrapper>
      <ContentTitle>
        <span>{value.title.rendered}</span>
      </ContentTitle>
      <ContentText>
        <p dangerouslySetInnerHTML={{ __html: value.acf.core_value }}></p>
      </ContentText>
    </BoxWrapper>
  )
}
export default CoreValueItem
