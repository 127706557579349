// Libraries
import React from "react"
import { HashRouter, Route, Switch } from "react-router-dom"

// Components - Pages
import LandingPage from "./components/LandingPage/LandingPage"
import FunPage from "./components/FunPage/FunPage"
import FunItemPage from "./components/FunPage/FunItemPage"
import AboutPage from "./components/AboutPage/AboutPage"
import ContactPage from "./components/ContactPage/ContactPage"
import CareerPage from "./components/CareerPage/CareerPage"
import JobItemPage from "./components/CareerPage/JobItemPage/JobItemPage"
import Navigation from "./components/Navigation/Navigation"
import SkarpCookieConsent from "./components/SkarpCookieConsent"
import Footer from "./components/Footer/Footer"
import ScrollArrow from "./components/Navigation/ScrollToTop/ScrollArrow"
import ScrollToTop from "./components/Navigation/ScrollToTop/ScrollToTop"

// Services
import { getServerIp } from "./services/proxy"

// Style
import "./App.css"

function App() {
  return (
    <div>
      <HashRouter>
        <Navigation />
        <Switch>
          <ScrollToTop>
            <div className="component-container">
              <Route exact path={["/", "/index.html"]} component={LandingPage} />
              <Route path="/about" component={AboutPage} />
              <Route exact path="/career" component={CareerPage} />
              <Route path="/career/jobs:id" component={JobItemPage} />
              <Route path="/contact/" component={ContactPage} />
              <Route exact path="/fun" component={FunPage} />
              <Route exact path="/fun/activity:id" component={FunItemPage} />
              <Route exact path="/wp-admin" render={() => (window.location = `${getServerIp()}/wp-admin}`)} />
            </div>
            <SkarpCookieConsent />
            <ScrollArrow />
            <Footer />
          </ScrollToTop>
        </Switch>
      </HashRouter>
    </div>
  )
}

export default App
